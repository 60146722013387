<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <validation-observer ref="addReceivingValidation">
      <b-card class="product-add-wrapper p-1">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Chọn Kho" rules="required">
                <h5 class for>
                  Chọn Kho
                  <span class="text-danger">*</span>
                </h5>
                <SelectInventory
                  v-model="inventoryInfo"
                  @onChangeInventory="handleChangeInventory"
                />

                <small class="text-danger">{{ errors[0] ? 'Vui lòng chọn Kho' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Nhà Phân phối" rules="required">
                <h5 class for>
                  Nhà phân phối
                  <span class="text-danger">*</span>
                </h5>

                <v-select
                  placeholder="Chọn nhà phân phối"
                  id="region-edit-vendor"
                  v-model="supplierInfo"
                  :options="productSuppliers"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <feather-icon icon="ChevronDownIcon" size="20" />
                    </span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] ? 'Chọn nhà phân phối' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <validation-provider name="Số Hợp đồng">
                <h5 class for>Số Hoá đơn</h5>
                <b-form-input v-model="numberInvoice" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider name="Ngày Hợp đồng">
                <h5 class for>Ngày Hoá đơn</h5>
                <b-form-datepicker v-model="invoiceDate" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <SelectMedicine
              @onUpdateList="handleUpdateListReceiving"
              :listReceiving="listReceiving"
            />
          </b-col>
          <b-col md="12 mt-2">
            <h4>Danh sách Thuốc nhập</h4>
            <b-table
              :tbody-tr-class="checkNew"
              responsive
              :items="listReceiving"
              :fields="tableColumns"
              class="cs-table"
            >
              <template #cell(product_code)="data">
                <span>{{ `#${data.item. product_code}`}}</span>
              </template>
              <template #cell(amount)="data">
                <b-form-input type="number" v-model="data.item.amount" />
              </template>
              <template #cell(price_in)="data">
                <b-form-input type="number" v-model="data.item.price_in" />
              </template>
              <template #cell(price_out)="data">
                <b-form-input type="number" v-model="data.item.price_out" />

                <!-- <span>{{ appUtils.numberFormat(data.item.price_out) }}</span> -->
                <!-- <b-form-input type="number" v-model="data.item.price_out" /> -->
              </template>
              <template #cell(active_ingredient)="data">
                <span>{{getActiveIngredient(data.item) }}</span>
              </template>
              <template #cell(vat_in)="data">
                <!-- <input>{{`${}%` }}</input> -->
                <b-form-input type="number" v-model="data.item.vat_in" />
              </template>
              <template #cell(lot_number)="data">
                <b-form-input v-model="data.item.lot_number" />
              </template>
              <template #cell(vat_out)="data">
                <!-- <span>{{`${data.item.vat_out}%` }}</span> -->
                <b-form-input type="number" v-model="data.item.vat_out" />
              </template>
              <template #cell(registration_number)="data">
                <!-- <span>{{`${data.item.registration_number}%` }}</span> -->
                <b-form-input v-model="data.item.registration_number" />
              </template>
              <template #cell(expired_date)="data">
                <b-form-input type="date" v-model="data.item.expired_date" />
                <!-- <span>{{formatExpiredDate(data.item) }}</span> -->
              </template>
              <template #cell(unit_id)="data">
                <span>{{getUnit(data.item) }}</span>
              </template>
              <!-- <template #cell(supplier)="data">
                <span>{{getSupplierLabel(data.item) }}</span>
              </template>-->
              <template #cell(action)="data">
                <span class="cursor-pointer" @click="handleDeleteMedicine(data.item)">
                  <feather-icon icon="Trash2Icon" size="24" class="text-danger" />
                </span>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12" class="mt-3 d-flex align-items-center justify-content-end">
            <b-button
              @click="saveReceiving"
              :disabled="loading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
            >Thêm phiếu nhập</b-button>
            <b-button
              :disabled="loading"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              @click="()=>{
                $router.go(-1)
              }"
              variant="outline-secondary"
            >Thoát</b-button>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import SelectMedicine from "./SelectMedicine.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import { RECEIVING_TYPE } from "@/utils/constant";
import SelectInventory from "@/components/SelectInventory/index.vue";
import vSelect from "vue-select";

const tableColumns = [
  {
    key: "product_code",
    label: "Mã thuốc",
    sortable: false,
    thStyle: { width: "8%" }
  },
  {
    key: "name",
    label: "Tên thuốc",
    sortable: false
  },
  { key: "active_ingredient", label: "Hoạt chất", sortable: false },
  { key: "unit_id", label: "Đơn vị", sortable: false },
  // {
  //   key: "lot_number",
  //   label: "Số lô",
  //   sortable: false,
  //   thStyle: { width: "12%" }
  // },
  {
    key: "lot_number",
    label: "Số lô",
    sortable: false,
    thStyle: { width: "8%" }
  },
  {
    key: "amount",
    label: "Số lượng",
    sortable: false,
    thStyle: { width: "8%" }
  },
  {
    key: "price_in",
    label: "Giá nhập",
    sortable: false,
    thStyle: { width: "10%" }
  },
  {
    key: "price_out",
    label: "Giá bán",
    sortable: false,
    thStyle: { width: "10%" }
  },
  {
    key: "vat_in",
    label: "VAT nhập(%)",
    sortable: false
  },
  {
    key: "vat_out",
    label: "VAT bán(%)",
    sortable: false
  },
  {
    key: "registration_number",
    label: "SĐK",
    sortable: false,
    thStyle: { width: "8%" }
  },
  {
    key: "expired_date",
    label: "Hạn sử dụng",
    sortable: false,

    thStyle: { width: "14%" }
  },
  // {
  //   key: "supplier",
  //   label: "Nhà cung cấp",
  //   sortable: false,
  //   thStyle: { width: "15%" }
  // },
  { key: "action", label: "", sortable: false, thStyle: { width: "2%" } }
];

export default {
  components: {
    SelectMedicine,
    ValidationObserver,
    ValidationProvider,
    SelectInventory,
    vSelect
  },
  directives: {
    Ripple
  },
  data() {
    return {
      loading: false,
      listReceiving: [],
      tableColumns,
      productSuppliers: [],
      inventories: [],
      supplierInfo: null,
      required,
      numberInvoice: "",
      invoiceDate: window.moment().format("YYYY-MM-DD"),
      inventoryInfo: null,
      appUtils
    };
  },
  created() {
    this.fetchSupplier();
  },
  methods: {
    async saveReceiving() {
      this.$refs.addReceivingValidation.validate().then(async success => {
        if (success) {
          try {
            const isValid = this.handleCheckMedicine();
            if (!isValid) return;
            this.loading = true;

            const userLocal = JSON.parse(localStorage.getItem("user"));

            if (!userLocal?.id) return;

            const day_contract = new Date(this.invoiceDate).getTime();

            const payload = {
              invoice_code: this.numberInvoice,
              day_contract: day_contract,
              supplier_id: this.supplierInfo.value,
              pharma_inventory_id: this.inventoryInfo.id,
              receiver_id: userLocal?.id,
              receiving_type: RECEIVING_TYPE.receiving,
              receiver_name: userLocal?.name,
              detail_receive: this.listReceiving.map(x => {
                const date = x.expired_date
                  ? new Date(x.expired_date).toISOString()
                  : "";

                return {
                  product_id: x.id,
                  qty: parseInt(x.amount),
                  input_price: this.formaterCurrencyToNumber(x.price_in),
                  price_out: this.formaterCurrencyToNumber(x.price_out),
                  vat_in: Number(x.vat_in),
                  vat_out: Number(x.vat_out),
                  registration_number: x.registration_number,
                  expired_date: date,
                  lot_number: String(x.lot_number)
                };
              })
            };
            await this.$store
              .dispatch("receiving/addReceiving", payload)
              .then(res => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Tạo phiếu nhập thành công",
                    icon: "CheckCircleIcon",
                    variant: "success"
                  }
                });
                this.$router.replace({
                  name: "ReceivingDetail",
                  params: {
                    id: res.data?.data?.id
                  }
                });
              });
          } catch (error) {
            console.log(error);
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tạo phiếu nhập thất bại",
                icon: "AlertTriangleIcon",
                variant: "danger"
              }
            });
          } finally {
            this.loading = false;
          }
        }
      });
    },
    handleUpdateListReceiving(value) {
      this.listReceiving.push({
        ...value,
        expired_date: this.formatExpiredDate(value),
        isNew: true
      });
    },
    checkNew(item, type) {
      if (!item || type !== "row") return;
      if (item.isNew) {
        setTimeout(() => {
          item.isNew = false;
        }, 1000);
        return "table-success";
      }
    },
    fetchSupplier() {
      this.$store
        .dispatch("phamarProductInventory/fetchSuppliers")
        .then(res => {
          this.productSuppliers = res.data.data.map(x => ({
            label: x.Supplier_name,
            value: x.ID
          }));
        });
    },
    formaterCurrencyToNumber(value) {
      return Number(value?.toString().replace(/[^0-9.-]+/g, ""));
    },
    getActiveIngredient(data) {
      return data?.active_ingredient_info?.name;
    },
    getUnit(data) {
      return data?.unit_info?.name || "";
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    formatExpiredDate(data) {
      if (
        !Number(data?.expiry_date_day) &&
        !Number(data?.expiry_date_month) &&
        !Number(data?.expiry_date_year)
      )
        return "";

      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `-0${data?.expiry_date_day}`
          : data?.expiry_date_day
          ? `-${data?.expiry_date_day}`
          : "-01";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `-0${data?.expiry_date_month}`
          : data?.expiry_date_month
          ? `-${data?.expiry_date_month}`
          : "-01";

      const year = data?.expiry_date_year ? `${data?.expiry_date_year}` : "";
      const dateResult = `${year}${month}${date}`;

      return dateResult;
    },
    getSupplierLabel(data) {
      return data?.supplier_info?.supplier_name || "";
    },
    handleDeleteMedicine(medicine) {
      this.listReceiving = this.listReceiving.filter(
        item => item?.id !== medicine?.id
      );
    },
    handleChangeInventory(data) {
      this.inventoryInfo = data;
    },
    handleCheckMedicine() {
      if (!this.listReceiving?.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vui lòng chọn danh mục s",
            icon: "AlertTriangleIcon",
            variant: "warning"
          }
        });

        return;
      }

      const isAllAmount = this.listReceiving?.every(item =>
        Number(item?.amount)
      );

      const isAllPrice = this.listReceiving?.every(
        item => Number(item?.price_in) && Number(item?.price_out)
      );

      const listExpiredDate = this.listReceiving
        .filter(item => !item?.expired_date)
        ?.map(item => item?.name);

      if (!isAllPrice) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vui lòng nhập đủ giá nhập, bán",
            icon: "AlertTriangleIcon",
            variant: "warning"
          }
        });

        return false;
      }

      if (!isAllAmount) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vui lòng nhập đủ số lượng",
            icon: "AlertTriangleIcon",
            variant: "warning"
          }
        });

        return false;
      }

      if (listExpiredDate?.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Vui lòng nhập hạn sử dụng cho ${listExpiredDate?.join(
              " ,"
            )}`,
            icon: "AlertTriangleIcon",
            variant: "warning"
          }
        });

        return false;
      }

      return true;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import "~swiper/css/swiper.css";
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
  display: none;
}

.is-new {
  background-color: rgba(29, 68, 177, 0.12);
}

.cs-table table {
  min-width: 1700px;
}
</style>
